<template>
  <div class="my-1">
    <span style="font-size: 10px" class="pl-4 grey--text text--darken-2">
      {{ messageObj.who }} @ {{ messageObj.timeStamp | formateTime }}
    </span>
    <v-container class="ml-2 d-flex justify-start align-end">
      <v-card elevation="1" rounded="lg" color="white" max-width="60%" class="py-2">
        <v-card-text class="py-2 body-2 black--text " v-html="messageObj.text">          
        </v-card-text>
        <div v-for="(option, i) in messageObj.options" :key="i" align="center">
          <v-divider class="my-2"></v-divider>
          <div class="px-2">
            <a              
              class="primary--text body-2 text--lighten-1 font-weight-medium"
              style="text-decoration: none"
            >
              {{ option.displayText }}
            </a>
          </div>
        </div>
      </v-card>      
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "optionBubble",
  props: {
    messageObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {   
  },
  filters: {
    formateTime(val) {
      if (!val || val === null) {
        return "-";
      }
      let date = moment(val).isValid()? val : val.toDate();
      return moment(date).format("h:mma");
    },
  },
};
</script>