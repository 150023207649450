<template>
  <v-card flat rounded="0">
    <v-card-title
      class="justify-center py-1"
      style="
        border-left: 1px solid lightgrey;
        border-right: 1px solid lightgrey;
      "
    >
      <span>Feed</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card
      class="overflow-y-auto overflow-x-hidden"
      flat
      :height="height"
      rounded="0"
      color="grey lighten-3"
    >
      <v-card-text v-if="messageObj.text === null" class="my-10" align="center">
        <div class="grey--text text--darken-1 my-10">
          Please select a user session :)
        </div>
      </v-card-text>

      <div v-else v-for="(message, i) in messageObj.messages" :key="i">
        <simpleBubble
          v-if="message.type === 'simple'"
          :messageObj="message"
        ></simpleBubble>
        <optionBubble
          v-if="message.type === 'options'"
          :messageObj="message"
        ></optionBubble>
        <redirectBubble
          v-if="message.type === 'redirect'"
          :messageObj="message"
        ></redirectBubble>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import simpleBubble from "@/components/chatAnalytics/simpleBubble.vue";
import optionBubble from "@/components/chatAnalytics/optionBubble.vue";
import redirectBubble from "@/components/chatAnalytics/redirectBubble.vue";
import { mapGetters } from 'vuex';
export default {
  name: "chatFeed",
  props: {
    messageObj: {
      type: Object,
      required: true,
    },
  },
  components: {
    simpleBubble,
    optionBubble,
    redirectBubble,
  },
  computed: {    
    height() {
      return window.innerHeight - 285;
    },
  },
  mounted() {
    console.log("messageOBJ", this.messageObj);
  },
};
</script>