<template>
  <div class="my-1">
    <div v-if="isBot()">
      <span style="font-size: 10px" class="pl-4 grey--text text--darken-2">
        {{ messageObj.who }} @ {{ messageObj.timeStamp | formateTime }}
      </span>
      <v-container class="ml-2 d-flex justify-start align-end">
        <v-card
          elevation="1"
          rounded="lg"
          color="white"
          max-width="60%"
          class="py-2"
        >
          <v-card-text class="py-2 body-2 black--text" v-html="messageObj.text">
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <div v-else>
      <span
        style="font-size: 10px"
        class="pr-4 grey--text text--darken-2 d-flex justify-end"
      >
        {{ messageObj.who }} @ {{ messageObj.timeStamp | formateTime }}
      </span>
      <v-container class="ml-n2 d-flex justify-end">
        <v-icon v-if="getIsChatDebuggingOn" color="error" left>{{interactionMode}}</v-icon>
        <v-card rounded="lg" color="primary" max-width="60%" dark class="py-2">
          <v-card-text class="py-2 body-2 white--text">
            {{ messageObj.text }}
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "simpleBubble",
  props: {
    messageObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getBotName", "getIsChatDebuggingOn"]),
    interactionMode() {
      switch (this.messageObj.interactionMode) {
        case "voice":
          return "mdi-microphone"
          // break;
        case "click":
          return "mdi-cursor-default-click"
          // break;
        case "keyboard":
          return "mdi-keyboard"
          // break;
        default:
          return "NA"
          // break;
      }
    },
  },
  methods: {
    isBot() {
      return this.messageObj.who === this.getBotName ? true : false;
    },
  },
  mounted() {},
  filters: {
    formateTime(val) {
      if (!val || val === null) {
        return "-";
      }
      let date = moment(val).isValid() ? val : val.toDate();
      return moment(date).format("h:mma");
    },
  },
};
</script>