<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4">
        Chat Analytics
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-combobox
            prepend-icon="mdi-calendar-range"
            v-model="range"
            :items="rangeList"
            dense
            color="primary"
            hide-details=""
          ></v-combobox>
        </v-card-actions>
      </v-card-title>
    </v-card>
    <v-card :height="height" elevation="0" class="py-1 mt-3">
      <v-row no-gutters class="fill-height">
        <v-col cols="3" align="left">
          <v-card-title class="justify-center py-1">
            <span>Chat Sessions</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-list
            two-line
            :max-height="height - 20"
            class="overflow-y-auto ma-0"
          >
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in getChats" :key="i">
                <v-list-item-avatar>
                  <v-icon> mdi-account-circle-outline </v-icon>
                </v-list-item-avatar>
                <v-list-item-content
                  align="left"
                  @click="selectedSession = item"
                >
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle style="font-size: 10px">
                    {{ item.createdAt | formatDate }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-subtitle class="caption">
                    {{ item.createdAt | formateTime }}</v-list-item-subtitle
                  >
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <chatFeed :messageObj="selectedSession"></chatFeed>
        </v-col>
        <v-col cols="3">
          <chatDetails :messageObj="selectedSession"></chatDetails>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import chatFeed from "@/components/chatAnalytics/chatFeed.vue";
import chatDetails from "@/components/chatAnalytics/chatDetails.vue";

export default {
  name: "chatAnalytics",
  data() {
    return {
      selectedItem: 0,
      selectedSession: { text: null },
      rangeList: [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",        
      ],
      range: "Today",
      resetKey: Math.floor(Math.random() * (1000 - 0 + 1) + 0),
    };
  },
  components: {
    chatFeed,
    chatDetails,
  },
  watch: {
    range(n) {
      this.fetchData(n);
      this.resetKey = Math.floor(Math.random() * (1000 - 0 + 1) + 0);
      console.log("RESET KEY:", this.resetKey);
    },
  },
  computed: {
    ...mapGetters(["getChats"]),
    height() {
      return window.innerHeight - 240;
    },
  },
  methods: {
    fetchData(range) {
      this.$store.dispatch("fetchChatData", range);
    },
  },
  mounted() {
    if (this.getChats.length === 0) {
      this.$store.dispatch("fetchChatData", this.range).then((response) => {
        console.log(`RESPONSE: ${response} ::: LENGTH ${this.getChats.length}`);
      });
    }
  },
  filters: {
    formateTime(val) {
      if (!val || val === null) {
        return "-";
      }
      let date = moment(val).isValid() ? val : val.toDate();
      return moment(date).format("h:mma");
    },
    formatDate(val) {
      if (!val || val === null) {
        return "-";
      }
      let date = moment(val).isValid() ? val : val.toDate();
      return moment(date).format("MMM Do YYYY");
    },
  },
};
</script>