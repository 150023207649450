<template>
  <v-card flat rounded="0">
    <v-card-title class="justify-center py-1">
      <span>Chat Details</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="messageObj.text === null" class="my-10" align="center">
      <div class="grey--text text--darken-1 my-10">
        Please select a user session :)
      </div>
    </v-card-text>
    <div v-else class="ml-2">
      <v-card-actions class="ml-3 py-0">
        <v-switch
          dense
          v-model="debugStatus"
          inset
          label="Debugging Mode"
        ></v-switch>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text class="grey--text text--darken-2">
        <v-icon color="primary" class="my-1" left>mdi-account</v-icon
        ><span>{{ messageObj.name }}</span>
        <br />
        <v-icon color="primary" class="my-1" left>mdi-email-outline</v-icon
        ><span>{{ messageObj.email }}</span>
        <br />
        <v-icon color="primary" class="my-1" left>mdi-clock-outline</v-icon
        ><span>{{ messageObj.createdAt | formatDate }}</span>
        <br />
        <v-icon color="primary" class="my-1" left>mdi-earth</v-icon
        ><span>USA</span>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-text align="center" class="py-3">
        <div class="pb-3">Interaction Analytics</div>

        <v-progress-linear
          rounded
          class="mb-2"
          v-model="clicks"
          color="primary lighten-2"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>Clicks: {{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>

        <v-progress-linear
          rounded
          class="mb-2"
          v-model="voice"
          color="amber lighten-1"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>Voice: {{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>

        <v-progress-linear
          rounded
          class="mb-2"
          v-model="typed"
          color="success lighten-1"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>Typed: {{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "chatDetails",
  props: {
    messageObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      skill: 40,
      voice: 0,
      clicks: 0,
      typed: 0,
    };
  },
  computed: {
    ...mapGetters(["getIsChatDebuggingOn", "getBotName"]),
    debugStatus: {
      get() {
        return this.getIsChatDebuggingOn;
      },
      set(val) {
        this.$store.commit("setIsChatDebuggingOn", val);
      },
    },
  },
  watch: {
    messageObj(n) {
      console.log("NEW");
      this.generateInteractionAnalytics();
    },
  },
  methods: {
    generateInteractionAnalytics() {
      let voice = 0;
      let clicks = 0;
      let typed = 0;
      let msgCount = 0;
      this.messageObj.messages.forEach((message) => {
        if (message.who !== this.getBotName) {
          msgCount = msgCount + 1;
          console.log(message);
          try {
            if (message.interactionMode === "voice") {
              voice = voice + 1;
            } else if (message.interactionMode === "click") {
              clicks = clicks + 1;
            } else if (message.interactionMode === "keyboard") {
              typed = typed + 1;
            }
          } catch (er) {
            // console.log(er)
          }
        }
      });
      this.voice = (voice / msgCount) * 100;
      this.clicks = (clicks / msgCount) * 100;
      this.typed = (typed / msgCount) * 100;
      console.log(`MESSAGE COUNT: ${msgCount}, VOICE: ${voice}`);
    },
  },
  mounted() {},
  filters: {
    formatDate(val) {
      if (!val || val === null) {
        return "-";
      }
      let date = moment(val).isValid() ? val : val.toDate();
      return moment(date).format("MMM Do YYYY, h:mma");
    },
  },
};
</script>