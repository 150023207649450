<template>
  <div class="my-1">
    <span style="font-size: 10px" class="pl-4 grey--text text--darken-2">
      {{ messageObj.who }} @ {{ messageObj.timeStamp | formateTime }}
    </span>
    <v-container class="ml-2 d-flex justify-start align-end">
      <v-card  rounded="lg" color="white" max-width="60%" class="py-2">
        <v-card-text class="py-2 body-2 black--text" v-html="messageObj.text">
        </v-card-text>
        <v-divider class="my-2"></v-divider>
        <div align="center">          
          <a
            class="primary--text text--lighten-1 font-weight-medium"
            style="text-decoration: none"                                    
          >
            {{redirectButtonText}}
          </a>
        </div>
      </v-card>    
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "redirectBubble",
  props: {
    messageObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed:{
    ...mapGetters(['getGlobalLanguage']),
    redirectButtonText(){
      if(this.messageObj.language === 'en-US'){
        return "Click Here"
      }
      else{
        return "Haga Clic Aquí"
      }
    }
  },
  methods: {    
  },
  filters: {
    formateTime(val) {
      if (!val || val === null) {
        return "-";
      }
      let date = moment(val).isValid() ? val : val.toDate();
      return moment(date).format("h:mma");
    },
  },
};
</script>